<template>
    <div class="h-full">
      <div :style="`min-height:` +  cardHeight + `px;`" class="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-4">
        <div @click="$router.push({ name: 'Users' })" style="height: 100px;" class="relative shadow cursor-pointer rounded p-4 bg-white mt-10" >
          <p class="text-right text-primary heading-3">
            User Management
          </p>
          <div class="card_icon bg-primary flex justify-center items-center">
            <i style="font-size: 36px; color: white;" >UM</i>
          </div>
        </div>
        <div @click="$router.push({ name: 'fields' })" style="height: 100px;" class="relative shadow cursor-pointer rounded p-4 bg-white mt-10" >
          <p class="text-right text-primary heading-3">
            Field Management
          </p>
          <div class="card_icon bg-primary flex justify-center items-center">
            <i style="font-size: 36px; color: white;" >FM</i>
          </div>
        </div>
      </div>
    </div>
  </template>
  <script>
  export default {
    components: {
    },
    name: "crmdash",
    props: [],
    data() {
      return {
        cardHeight: 0,
      };
    },
    created() {
      document.title= 'HRM Dahsboard'
      window.addEventListener("resize", this.resizeWindowHandler);
    },
    mounted() {
      this.resizeWindowHandler();
    },
    watch: {},
    methods: {
      resizeWindowHandler() {
        if (window.innerWidth < 1024) {
          this.mobileView = true;
        } else {
          this.mobileView = false;
        }
        if (window.innerWidth < 600) {
          this.extraSmall = true;
        } else {
          this.extraSmall = false;
        }
      },
    },
  };
  </script>
  
  <style scoped>
  .card-content {
    background-color: transparent;
    padding: 0rem;
    padding-top: 1rem;
    padding-right: 1rem;
    padding-bottom: 0.5rem;
    padding-left: 1rem;
  }
  .sheets {
    border-radius: 10%;
    width: 70px !important;
    height: 67px !important;
    justify-content: center;
    display: flex;
    margin-top: -40px;
    border-color: #26c6da;
  }
  .hoverableCard:hover {
    box-shadow: 1px 5px 5px 1px rgba(38, 38, 38, 0.2);
    top: 0px;
    border: 0px solid #cccccc;
    /* background-color: rgb(244, 244, 244); */
    /* box-shadow: 0px 5px 5px 0px rgba(0,0,0,0.2); */
    /* transition:all 0.5s; */
    /* border: 0px solid #cccccc; */
  }
  .cardAbsolute {
    position: absolute;
    left: 20px;
    top: -30px;
    border-radius: 8%;
    width: 80px !important;
    height: 80px !important;
    text-align: center;
    justify-content: center;
    display: flex;
  }
  .marginTop {
    margin-top: 30px;
  }
  
  .card_icon{
        position: absolute;
      left: 20px;
      top: -30px;
      border-radius: 8%;
      width: 80px!important;
      height: 80px!important;
      text-align: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
  }
  </style>
  
  